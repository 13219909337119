<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="dataList"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="branch_code">Branch Code</vs-th>
        <vs-th sort-key="sku_code">SKU Code</vs-th>
        <vs-th sort-key="division">Division</vs-th>
        <vs-th sort-key="stor_location">Stor. Location</vs-th>
        <vs-th sort-key="last_goods_rec">Last goods rec.</vs-th>
        <!-- <vs-th sort-key="sled">SLED/BBD</vs-th> -->
        <vs-th sort-key="batch">Batch</vs-th>
        <vs-th sort-key="available_qty">Available Qty</vs-th>
        <!-- <vs-th sort-key="reserved_qty">Reserved Qty</vs-th>
        <vs-th sort-key="unit_of_meassure">Unit of Meassure</vs-th> -->
        <!-- <vs-th sort-key="region">Region</vs-th> -->
        <vs-th sort-key="branch_name">Branch Name</vs-th>
        <vs-th sort-key="sku_name">SKU Name</vs-th>
        <vs-th sort-key="status_sku">Status SKU</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="branch">Branch</vs-th>
        <vs-th sort-key="material_desc">Material Desc</vs-th>
        <vs-th sort-key="principal">Principal</vs-th>
        <!-- <vs-th sort-key="category_gaga">Category GAGA</vs-th> -->
        <vs-th sort-key="on_hand_qty">On Hand Qty</vs-th>
        <vs-th sort-key="convert_qty">Convert Qty</vs-th>
        <!-- <vs-th sort-key="group_ed">Group ED</vs-th> -->
        <vs-th sort-key="aging">Aging</vs-th>
        <vs-th sort-key="map">MAP</vs-th>
        <!-- <vs-th sort-key="value">Inventory Value</vs-th>
        <vs-th sort-key="year_month_ed">Year & Month ED</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].branch_code">
            {{ data[indextr].branch_code }}
          </vs-td>
          <vs-td :data="data[indextr].sku_code">
            {{ data[indextr].sku_code }}
          </vs-td>
          <vs-td :data="data[indextr].division">
            {{ data[indextr].division }}
          </vs-td>
          <vs-td :data="data[indextr].stor_location">
            {{ data[indextr].stor_location }}
          </vs-td>
          <vs-td :data="data[indextr].last_goods_rec">
            {{ dateFormat(data[indextr].last_goods_rec) }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].sled">
            {{ data[indextr].sled }}
          </vs-td> -->
          <vs-td :data="data[indextr].batch">
            {{ data[indextr].batch }}
          </vs-td>
          <vs-td :data="data[indextr].available_qty">
            {{ data[indextr].available_qty }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].reserved_qty">
            {{ data[indextr].reserved_qty }}
          </vs-td>
          <vs-td :data="data[indextr].unit_of_meassure">
            {{ data[indextr].unit_of_meassure }}
          </vs-td> -->
          <!-- <vs-td :data="data[indextr].region">
            {{ data[indextr].region }}
          </vs-td> -->
          <vs-td :data="data[indextr].branch_name">
            {{ data[indextr].branch_name }}
          </vs-td>
          <vs-td :data="data[indextr].sku_name">
            {{ data[indextr].sku_name }}
          </vs-td>
          <vs-td :data="data[indextr].status_sku">
            {{ data[indextr].status_sku }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td :data="data[indextr].branch">
            {{ data[indextr].branch }}
          </vs-td>
          <vs-td :data="data[indextr].material_desc">
            {{ data[indextr].material_desc }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].category_gaga">
            {{ data[indextr].category_gaga }}
          </vs-td> -->
          <vs-td :data="data[indextr].principal">
            {{ data[indextr].principal }}
          </vs-td>
          <vs-td :data="data[indextr].on_hand_qty">
            {{ data[indextr].on_hand_qty }}
          </vs-td>
          <vs-td :data="data[indextr].convert_qty">
            {{ data[indextr].convert_qty }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].group_ed">
            {{ data[indextr].group_ed }}
          </vs-td> -->
          <vs-td :data="data[indextr].aging">
            {{ data[indextr].aging }}
          </vs-td>
          <vs-td :data="data[indextr].map">
            {{ data[indextr].map }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].value">
            {{ data[indextr].value }}
          </vs-td>
          <vs-td :data="data[indextr].year_month_ed">
            {{ data[indextr].year_month_ed }}
          </vs-td> -->
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
export default {
  props: {
    draw: {
      type: Number,
    },
    ouIDs: {
      type: Array,
    },
    territoryIDs: {
      type: Array,
    },
    storageAreaIDs: {
      type: Array,
    },
    principalIDs: {
      type: Array,
    },
    skuCodeIDs: {
      type: Array,
    },
    batchNumberIDs: {
      type: Array,
    },
    groupEdIDs: {
      type: Array,
    },
    bucketAgingIDs: {
      type: Array,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ 
      getExpiredReport: 'reportExpired/getExpiredReport',
      generateExpiredReport: 'reportExpired/generateExpiredReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'id',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {},
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getExpiredReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          ou: this.ouIDs,
          territory: this.territoryIDs,
          storage_area: this.storageAreaIDs,
          principal: this.principalIDs,
          sku_code: this.skuCodeIDs,
          batch_number: this.batchNumberIDs,
          group_ed: this.groupEdIDs,
          bucket_aging: this.bucketAgingIDs,
          start_date:
            this.startDate == null
              ? undefined
              : moment(this.startDate).format('YYYY-MM-DD'),
          end_date:
            this.endDate == null
              ? undefined
              : moment(this.endDate).format('YYYY-MM-DD'),
        }).then(() => {
          this.$vs.loading.close();
        });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ''
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
            this.$vs.loading();
            this.generateExpiredReport({
              length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          ou: this.ouIDs,
          territory: this.territoryIDs,
          storage_area: this.storageAreaIDs,
          principal: this.principalIDs,
          sku_code: this.skuCodeIDs,
          batch_number: this.batchNumberIDs,
          group_ed: this.groupEdIDs,
          bucket_aging: this.bucketAgingIDs,
          start_date:
            this.startDate == null
              ? undefined
              : moment(this.startDate).format('YYYY-MM-DD'),
          end_date:
            this.endDate == null
              ? undefined
              : moment(this.endDate).format('YYYY-MM-DD'),
                }).then(() => {
                    this.$vs.loading.close();
                }).catch(() => {
                    this.$vs.loading.close();
                })
            
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  computed: {
    ...mapState({
      dataList: (state) => state.reportExpired.dataList,
      totalPage: (state) => state.reportExpired.totalPage,
      generateReport: (state) => state.reportExpired.generateReport,
      totalRecord: (state) => state.reportExpired.total_record,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  mounted(){
    this.getData()
  },
  watch: {
    draw() {
      this.getData();
    },
    generateReport(val) {
      if (val && val.status === 'OK') {
        this.$vs.notify({
          color: "success",
          title: "Processing",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    totalRecord(val) {
      this.table.total = val
    },
  },
};
</script>
